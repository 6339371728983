var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasRejectFeedback ? _c('purchase-dialog-claim-reject', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          title: _vm.title
        })];
      }
    }], null, true)
  }, 'purchase-dialog-claim-reject', {
    value: _vm.value,
    type: _vm.type
  }, false)) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }