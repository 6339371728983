var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-done-all",
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M23.0608 6.99999L12.5304 17.5303C12.2375 17.8232 11.7627 17.8232 11.4698 17.5303L10.3145 16.375L11.3751 15.3143L12.0001 15.9393L22.0001 5.93933L23.0608 6.99999ZM7.00011 10.9393L8.68577 12.625L7.62511 13.6857L5.93945 12L7.00011 10.9393Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.0608 6.99999L7.53044 17.5303C7.23755 17.8232 6.76268 17.8232 6.46978 17.5303L0.939453 12L2.00011 10.9393L7.00011 15.9393L17.0001 5.93933L18.0608 6.99999Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }