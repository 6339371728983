var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._g(_vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }]
  }, 'v-btn', Object.assign({}, _vm.$attrs, {
    disabled: _vm.disabled,
    loading: _vm.loading
  }), false), {
    click: _vm.click
  }), [_vm._v("구매확정")]);

}
var staticRenderFns = []

export { render, staticRenderFns }