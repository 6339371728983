var render = function render(){
  var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": _vm.typeText + ' 거절 사유',
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('div', {
    staticClass: "tit tit--xs pb-12px"
  }, [_vm._v(_vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDate) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)))]), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('purchase-item', {
    attrs: {
      "value": _vm.form,
      "mode": "view",
      "withQuantity": ""
    }
  }), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "거절사유"
    }
  }), _c('v-textarea', _vm._b({
    attrs: {
      "value": _vm.form.feedbackMessage || `${_vm.typeText} 신청이 거절되었습니다`,
      "readonly": ""
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }