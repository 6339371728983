<template>
    <inquire-form :product="value?.product" v-if="inquirable">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
    </inquire-form>
</template>

<script>
import { initPurchase, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

import ReviewForm from "@/components/client/shop/reviews/review-form.vue";
import InquireForm from "@/components/client/shop/inquires/inquire-form.vue";

export default {
    components: {
        ReviewForm,
        InquireForm,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchase
    },
    computed: {
        inquirable() {
            const isCompleted = this.value.purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value;
            return isCompleted;
        },
    },
};
</script>

<style></style>
