<template>
    <v-row align-md="center" class="ma-n8px">
        <v-col cols="auto" class="pa-8px">
            <v-card rounded="lg">
                <v-img min-width="100" :aspect-ratio="1 / 1" :src="product.thumb?.url" />
            </v-card>
        </v-col>
        <v-col class="pa-8px">
            <router-link :to="`/shop/products/${product?._id}`" class="d-block mb-12px">
                <span class="txt txt--sm txt--dark font-weight-medium">{{ product.name }}</span>
            </router-link>
            <v-row align="center" class="row--xxs">
                <v-col cols="12" sm="auto">
                    <v-chip v-if="purchase._option" x-small outlined color="grey">옵션</v-chip>
                    <v-chip v-if="purchase._supply" x-small outlined color="grey">추가</v-chip>
                </v-col>
                <v-col cols="12" sm="">
                    <span class="txt txt--xs d-sm-inline-block pt-sm-4px">
                        {{ $decode__productOptionName(purchase.name) }} / {{ purchase.amount.format() }}개 <span v-if="purchase._supply"> (+{{ purchase.price.format() }}원)</span>
                    </span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        product: { type: Object, default: () => ({}) },
        purchase: { type: Object, default: () => ({}) },
    },
};
</script>

<style></style>
