var render = function render(){
  var _vm$points, _vm$points2, _vm$points3, _vm$points3$reviewPoi, _vm$points3$reviewPoi2, _vm$points4, _vm$points4$imagePoin, _vm$points5, _vm$points6, _vm$points6$reviewPoi, _vm$points7, _vm$points7$reviewPoi, _vm$SHOP_POINT_UNITS$, _vm$points8, _vm$points8$reviewPoi, _vm$points9, _vm$points10, _vm$points10$imagePoi, _vm$points10$imagePoi2, _vm$points11, _vm$points11$imagePoi, _vm$SHOP_POINT_UNITS$2, _vm$points12, _vm$points12$imagePoi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "persistent": "",
      "title": "리뷰 작성",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": function () {
              return _vm.shows = false;
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v(_vm._s(_vm.isCreate ? "작성" : "수정"))])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', [_c('page-section', {
    staticClass: "page-section--xs pt-0"
  }, [_c('review-purchase-item', _vm._b({}, 'review-purchase-item', {
    product: _vm.product,
    purchase: _vm.purchase
  }, false))], 1), _c('v-divider'), ((_vm$points = _vm.points) !== null && _vm$points !== void 0 && _vm$points.reviewPoint || (_vm$points2 = _vm.points) !== null && _vm$points2 !== void 0 && _vm$points2.imagePoint) && ((_vm$points3 = _vm.points) !== null && _vm$points3 !== void 0 && (_vm$points3$reviewPoi = _vm$points3.reviewPoint) !== null && _vm$points3$reviewPoi !== void 0 && (_vm$points3$reviewPoi2 = _vm$points3$reviewPoi.issue) !== null && _vm$points3$reviewPoi2 !== void 0 && _vm$points3$reviewPoi2.isActive || (_vm$points4 = _vm.points) !== null && _vm$points4 !== void 0 && (_vm$points4$imagePoin = _vm$points4.imagePoint) !== null && _vm$points4$imagePoin !== void 0 && _vm$points4$imagePoin.issue.isActive) ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "리뷰포인트 정보"
    }
  }), _c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px"
  }, [(_vm$points5 = _vm.points) !== null && _vm$points5 !== void 0 && _vm$points5.reviewPoint && (_vm$points6 = _vm.points) !== null && _vm$points6 !== void 0 && (_vm$points6$reviewPoi = _vm$points6.reviewPoint) !== null && _vm$points6$reviewPoi !== void 0 && _vm$points6$reviewPoi.issue.isActive ? _c('div', [_c('v-row', {
    staticClass: "txt txt--xs",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 리뷰 작성 시 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s((_vm$points7 = _vm.points) === null || _vm$points7 === void 0 ? void 0 : (_vm$points7$reviewPoi = _vm$points7.reviewPoint) === null || _vm$points7$reviewPoi === void 0 ? void 0 : _vm$points7$reviewPoi.value) + _vm._s((_vm$SHOP_POINT_UNITS$ = _vm.SHOP_POINT_UNITS[(_vm$points8 = _vm.points) === null || _vm$points8 === void 0 ? void 0 : (_vm$points8$reviewPoi = _vm$points8.reviewPoint) === null || _vm$points8$reviewPoi === void 0 ? void 0 : _vm$points8$reviewPoi.unit]) === null || _vm$SHOP_POINT_UNITS$ === void 0 ? void 0 : _vm$SHOP_POINT_UNITS$.suffix) + " 적립")])])], 1)], 1) : _vm._e(), (_vm$points9 = _vm.points) !== null && _vm$points9 !== void 0 && _vm$points9.imagePoint && (_vm$points10 = _vm.points) !== null && _vm$points10 !== void 0 && (_vm$points10$imagePoi = _vm$points10.imagePoint) !== null && _vm$points10$imagePoi !== void 0 && (_vm$points10$imagePoi2 = _vm$points10$imagePoi.issue) !== null && _vm$points10$imagePoi2 !== void 0 && _vm$points10$imagePoi2.isActive ? _c('div', [_c('v-row', {
    staticClass: "txt txt--xs",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 포토리뷰 작성 시 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s((_vm$points11 = _vm.points) === null || _vm$points11 === void 0 ? void 0 : (_vm$points11$imagePoi = _vm$points11.imagePoint) === null || _vm$points11$imagePoi === void 0 ? void 0 : _vm$points11$imagePoi.value) + _vm._s((_vm$SHOP_POINT_UNITS$2 = _vm.SHOP_POINT_UNITS[(_vm$points12 = _vm.points) === null || _vm$points12 === void 0 ? void 0 : (_vm$points12$imagePoi = _vm$points12.imagePoint) === null || _vm$points12$imagePoi === void 0 ? void 0 : _vm$points12$imagePoi.unit]) === null || _vm$SHOP_POINT_UNITS$2 === void 0 ? void 0 : _vm$SHOP_POINT_UNITS$2.suffix) + " 적립")])])], 1)], 1) : _vm._e()])])], 1) : _vm._e(), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("상품 만족도 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }), _c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-32px"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("이 상품에 대해서 얼마나 만족하시나요?")]), _c('div', {
    staticClass: "font-size-20 font-size-md-36 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.form.star || 0))]), _c('v-rating', {
    attrs: {
      "half-increments": "",
      "color": "grey darken-4",
      "background-color": "grey darken-4"
    },
    model: {
      value: _vm.form.star,
      callback: function ($$v) {
        _vm.$set(_vm.form, "star", $$v);
      },
      expression: "form.star"
    }
  }), _c('div', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v("별점을 선택해 주세요.")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("리뷰 작성 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }), _c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "리뷰를 작성해 주세요."
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("사진 첨부"), _c('span', {
          staticClass: "txt--light font-size-12 font-weight-regular pl-8px"
        }, [_vm._v("사진은 최대 20MB 이하의 JPG, PNG, GIF 파일 10장까지 첨부 가능합니다.")])])];
      },
      proxy: true
    }])
  }), _c('form-files', {
    model: {
      value: _vm.form.images,
      callback: function ($$v) {
        _vm.$set(_vm.form, "images", $$v);
      },
      expression: "form.images"
    }
  }), _c('div', {
    staticClass: "pt-12px"
  }, [_c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px"
  }, [_c('div', {
    staticClass: "font-size-12 red--text"
  }, [_vm._v("*사진 첨부 시 포인트를 추가 적립해 드리며, 제품과 무관한 사진 등록 시 포인트 차감의 사유가 될 수 있습니다.")])])])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }