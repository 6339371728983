<template>
    <v-col v-if="href" cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0"><slot name="activator" v-bind="{ attrs: { href, target: '_blank' }, on: {} }" /></v-col>
</template>

<script>
export default {
    components: {},
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        type: { type: String, default: "cancel" },
    },
    computed: {
        href() {
            let { url, number } = this.value?.delivery || {};
            if (url && number) return `${url}${number}`;
        },
    },
};
</script>

<style></style>
