<template>
    <v-form v-model="isValid" v-if="form">
        <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">• </span>필수입력 항목입니다.</div>
        <vertical-form-table v-model="form" v-bind="{ items }" class="vertical-form-table--border">
            <template slot="아이디">
                {{ form?.username }}
            </template>
            <template slot="비밀번호 확인">
                <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." isModify class="w-md-358px"/>
            </template>
            <template slot="마케팅 및 광고 활용 동의">
                <v-row class="row--xs">
                    <v-col cols="auto">
                        <v-checkbox v-model="form.meta.isReceiveEmail" v-bind="{ ...checkbox_primary }" label="이메일수신" />
                    </v-col>
                    <v-col cols="auto">
                        <v-checkbox v-model="form.meta.isReceiveSMS" v-bind="{ ...checkbox_primary }" label="SMS 수신" />
                    </v-col>
                </v-row>
            </template>
            <template slot="사업장 주소">
                <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
            </template>
            <!-- <template slot="휴대폰 번호">
                <myinfo-phone-field v-model="form.phone" :type="form.type"  dense outlined  @input="certify"/>
            </template> -->

            <!-- <template slot="추천인코드">
                {{ form?.code_promoter?.code }}
            </template> -->
        </vertical-form-table>
        <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px">
            <v-row justify="center" class="ma-0px ma-md-n4px">
                <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                    <v-btn v-bind="{ ...btn_primary, disabled, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="emit">수정</v-btn>
                </v-col>
                <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                    <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$emit('cancel')">취소</v-btn>
                    <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$emit('cancel')">취소</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import { initUser, rules } from '@/assets/variables';
import { btn_primary, btn_primary3, btn_secondary, checkbox_primary, radio_primary, attrs_input } from "@/assets/variables";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import MyinfoPhoneField from "./myinfo-phone-field.vue"
const items = [
    {
        term: "아이디",
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        placeholder: "비밀번호 변경 시 새로운 비밀번호를 입력하세요",
        rules: rules.passwordModify,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "companyName",
        term: "업체명",
        type: "text",
        required: true,
        rules: rules.companyName,
        ...attrs_input,
    },
    {
        key: "ceoName",
        term: "대표자명",
        type: "text",
        required: true,
        rules: rules.ceoName,
        ...attrs_input,
    },
    {
        key: "registrationNumber",
        term: "사업자등록번호",
        type: "text",
        required: true,
        rules: rules.registrationNumber,
        ...attrs_input,
    },
    {
        key: "businessRegistration",
        term: "사업자 등록증 사본",
        type: "file",
        required: true,
        accept: ["image/*", "application/pdf"],
        rules: rules.businessRegistration,
        ...attrs_input,
    },
    {
        key: "licenseCode",
        term: "통신판매업 번호",
        type: "text",
        required: true,
        rules: rules.licenseCode,
        ...attrs_input,
    },
    {
        term: "마케팅 및 광고 활용 동의",
    },
    {
        term: "사업장 주소",
        required: true,
    },
    {
        key: "name",
        term: "담당자 명",
        type: "text",
        required: true,
        // disabled: true,
        ...attrs_input,
    },
    {
        key: "department",
        term: "담당자 부서명",
        type: "text",
        ...attrs_input,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        rules: rules.email,
        required: true,
        ...attrs_input,
    },
    {
        key: "nickname",
        term: "닉네임",
        type: "text",
        required: true,
        class: "w-md-358px",
        rules: rules.nickname,
    },
    {
        key: "tel",
        term: "전화번호",
        type: "phone",
        ...attrs_input,
    },
    {
        key: "phone",
        term: "휴대폰 번호",
        type: "phone",
        ...attrs_input,
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components:{
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        MyinfoPhoneField
    },
    props: {
        value: { type: Object, default: initUser }, //form
        loading: { type: Boolean, default: false }
    },
    data: () => ({
        btn_primary,
        btn_primary3,
        btn_secondary,
        checkbox_primary,
        radio_primary,
        attrs_input,
        items,
        form: initUser(),
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    methods: {
        init(){
            this.sync();
        },
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        certify(value){
            this.form = initUser({
                ...this.form,
                ...value,
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        }
    }
}
</script>

<style>

</style>