var render = function render(){
  var _vm$purchase, _vm$purchase$delivery, _vm$purchase2, _vm$purchase2$deliver, _vm$purchase3, _vm$purchase3$deliver, _vm$purchase4, _vm$purchase4$deliver;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "배송정보",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          shows: _vm.shows
        })];
      }
    }], null, true)
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "value": _vm.purchase,
      "mode": "view"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "송장번호"
  }, [(_vm$purchase = _vm.purchase) !== null && _vm$purchase !== void 0 && (_vm$purchase$delivery = _vm$purchase.delivery) !== null && _vm$purchase$delivery !== void 0 && _vm$purchase$delivery.url ? [_c('a', {
    attrs: {
      "href": `${_vm.purchase.delivery.url}${((_vm$purchase2 = _vm.purchase) === null || _vm$purchase2 === void 0 ? void 0 : (_vm$purchase2$deliver = _vm$purchase2.delivery) === null || _vm$purchase2$deliver === void 0 ? void 0 : _vm$purchase2$deliver.number) || ''}`,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$purchase3 = _vm.purchase) === null || _vm$purchase3 === void 0 ? void 0 : (_vm$purchase3$deliver = _vm$purchase3.delivery) === null || _vm$purchase3$deliver === void 0 ? void 0 : _vm$purchase3$deliver.number) + " ")])] : [_vm._v(" " + _vm._s(((_vm$purchase4 = _vm.purchase) === null || _vm$purchase4 === void 0 ? void 0 : (_vm$purchase4$deliver = _vm$purchase4.delivery) === null || _vm$purchase4$deliver === void 0 ? void 0 : _vm$purchase4$deliver.number) || "-") + " ")]], 2)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }