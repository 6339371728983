var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-receipt",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M22.2315 21.0537V3.72533C22.2315 2.22251 21.009 1 19.5062 1H5.12554C5.10316 1 5.07685 1.00263 5.05118 1.00658L5.01171 1.04606L4.92091 1.02829L4.83471 1.04277L4.79786 1.00658C4.74325 1.00066 4.67746 1.00066 4.60968 1.00066C3.04305 1 1.76855 2.27449 1.76855 3.84113V13.3265C1.76855 13.7357 2.1107 14.0752 2.53049 14.0831L5.97958 14.0943V21.0537C5.97958 21.6202 6.29739 22.1367 6.80929 22.4012C7.31593 22.6546 7.91403 22.6006 8.36934 22.2591L10.8815 20.4122L13.0508 22.5703C13.3239 22.8434 13.7081 23 14.1056 23C14.503 23 14.8826 22.8473 15.1596 22.571L17.3408 20.401L19.8405 22.2585C20.2945 22.5901 20.9083 22.6421 21.4038 22.3901C21.9144 22.1348 22.2315 21.6229 22.2315 21.0537ZM5.97958 12.5698H3.28189V3.84113C3.28189 3.0963 3.88394 2.51334 4.65179 2.51334C5.41965 2.51334 5.97958 3.1088 5.97958 3.84113V12.5698ZM20.7182 21.0195L18.2245 19.1752C17.6264 18.7357 16.8098 18.7988 16.2834 19.3252L14.1049 21.4807L11.9389 19.3259C11.6592 19.0462 11.275 18.8857 10.8841 18.8857C10.5564 18.8857 10.2544 18.9864 9.98664 19.1851L7.49292 21.0294V2.51334H19.5062C20.1747 2.51334 20.7182 3.05682 20.7182 3.72533V21.0195Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.1471 11.5229H11.0164C10.5993 11.5229 10.2598 11.8625 10.2598 12.2796C10.2598 12.6968 10.5993 13.0363 11.0164 13.0363H14.1471C14.5642 13.0363 14.9037 12.6968 14.9037 12.2796C14.9037 11.8625 14.5642 11.5229 14.1471 11.5229Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.3053 5.20654H11.0164C10.5993 5.20654 10.2598 5.54606 10.2598 5.96321C10.2598 6.38037 10.5993 6.71988 11.0164 6.71988H17.3053C17.7225 6.71988 18.062 6.38037 18.062 5.96321C18.062 5.54606 17.7225 5.20654 17.3053 5.20654Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.3053 8.36475H11.0164C10.5993 8.36475 10.2598 8.70426 10.2598 9.12142C10.2598 9.53857 10.5993 9.87808 11.0164 9.87808H17.3053C17.7225 9.87808 18.062 9.53857 18.062 9.12142C18.062 8.70426 17.7225 8.36475 17.3053 8.36475Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.3053 14.6812H11.0164C10.5993 14.6812 10.2598 15.0207 10.2598 15.4378C10.2598 15.855 10.5993 16.1945 11.0164 16.1945H17.3053C17.7225 16.1945 18.062 15.855 18.062 15.4378C18.062 15.0207 17.7225 14.6812 17.3053 14.6812Z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }