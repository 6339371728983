<template>
    <v-sheet>
        <v-row align-md="center">
            <v-col cols="auto" class="pa-8px">
                <v-card>
                    <v-img v-bind="{ src }" width="78" height="78">
                        <v-fade-transition>
                            <v-overlay v-show="!src" absolute>
                                <v-icon>mdi-image-broken</v-icon>
                            </v-overlay>
                        </v-fade-transition>
                    </v-img>
                </v-card>
            </v-col>
            <v-col class="pa-8px">
                <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ product.name }}</div>
                <v-row class="row--xxs">
                    <v-col cols="12" sm="auto">
                        <v-chip x-small outlined color="grey">
                            {{ purchase._option ? "옵션" : "" }}
                            {{ purchase._supply ? "추가" : "" }}
                        </v-chip>
                    </v-col>
                    <v-col cols="12" sm="">
                        <span class="txt txt--xs">{{ $decode__productOptionName(option) }}</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { initProduct } from "@/assets/variables";
export default {
    props: {
        product: { type: Object, default: initProduct },
        purchase: { type: Object, default: () => ({}) },
        purchaseName: { type: String, default: null },
        purchaseAmount: { type: [Number, String], default: null },
    },
    computed: {
        src() {
            return this.product?.thumb?.url;
        },
        option() {
            let option = this.purchaseName || "";
            if (this.purchaseAmount) option += ` / ${this.purchaseAmount}개`;
            return option;
        },
    },
};
</script>

<style>
</style>