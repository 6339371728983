var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.href ? _c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-shrink-0",
    attrs: {
      "cols": "4",
      "md": "12"
    }
  }, [_vm._t("activator", null, null, {
    attrs: {
      href: _vm.href,
      target: '_blank'
    },
    on: {}
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }