<template>
    <purchase-dialog-claim-reject v-if="hasRejectFeedback" v-bind="{ value, type }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, title }" />
        </template>
    </purchase-dialog-claim-reject>
</template>

<script>
import { PURCHASE_CLAIM_STATUSES } from "@/assets/variables";
import PurchaseDialogClaimReject from "./purchase-dialog-claim-reject.vue";
export default {
    components: {
        PurchaseDialogClaimReject,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
    },
    computed: {
        type() {
            return this.value?.claimStatus;
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value]: "교환·반품",
            }[this.type];
        },
        hasRejectFeedback() {
            return [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value, PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value].includes(this.type);
        },
    },
};
</script>

<style></style>
