var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-delivery",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.1472 18.2354C13.0192 18.261 12.8867 18.2528 12.7628 18.2115L9.05132 16.9743L9.52566 15.5513L13.0486 16.7256L13.1785 16.6998C13.386 16.6587 13.6835 16.6 14.0435 16.5296C14.7634 16.3887 15.7342 16.2008 16.7368 16.0128C17.7386 15.825 18.7756 15.6364 19.6267 15.4946C20.442 15.3587 21.1726 15.25 21.5 15.25C21.8668 15.25 22.2527 15.3417 22.5564 15.6074C22.8732 15.8847 23 16.2631 23 16.625C23 17.2917 22.5879 17.9727 22.0303 18.5303C21.4988 19.0618 20.3317 20.0984 18.9027 21.0077C17.4946 21.9038 15.7193 22.75 14 22.75C12.373 22.75 11.0272 22.3413 9.88783 21.9615C9.77776 21.9248 9.67051 21.8888 9.56581 21.8536C8.54574 21.511 7.76858 21.25 7 21.25H2.00055L2 20.5L2.00003 19.75H7C8.03069 19.75 9.05304 20.096 10.0212 20.4237C10.1356 20.4624 10.2493 20.5009 10.3622 20.5385C11.4728 20.9087 12.627 21.25 14 21.25C15.2807 21.25 16.7554 20.5962 18.0973 19.7423C19.4183 18.9016 20.5012 17.9382 20.9697 17.4697C21.1774 17.262 21.3166 17.0704 21.4007 16.9127C21.4351 16.8482 21.4578 16.7941 21.4727 16.7505C21.2717 16.7576 20.7042 16.8357 19.8733 16.9742C19.0369 17.1136 18.0114 17.3 17.0132 17.4872C16.0158 17.6742 15.0491 17.8613 14.3315 18.0017C13.9728 18.0719 13.6765 18.1303 13.47 18.1712L13.1472 18.2354ZM21.6061 16.7615C21.6061 16.7615 21.6055 16.7613 21.6043 16.7607ZM2 20.5L2.00003 19.75H1.25V21.25H2.00055L2 20.5Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.49722 13.5262C3.68996 13.9875 2.99208 14.5687 2.53044 15.0303L2.00011 15.5607L0.939453 14.5L1.46978 13.9697C2.00815 13.4313 2.81027 12.7625 3.75301 12.2238C4.68956 11.6886 5.82069 11.25 7.00011 11.25C7.60134 11.25 8.35014 11.3961 9.10923 11.605C9.88116 11.8174 10.7143 12.109 11.5023 12.4306C12.2895 12.7519 13.0457 13.1089 13.6614 13.456C13.969 13.6294 14.2507 13.8055 14.4874 13.9787C14.7144 14.1448 14.9404 14.337 15.1001 14.55C15.4845 15.0625 15.4511 15.6314 15.2844 16.0758C15.1279 16.4931 14.8393 16.8598 14.5785 17.138C14.3089 17.4255 14.0193 17.6703 13.8028 17.8401C13.6932 17.9261 13.5993 17.9952 13.5317 18.0436C13.4979 18.0678 13.4704 18.0869 13.4508 18.1005L13.4272 18.1166L13.42 18.1214L13.4177 18.123L13.4168 18.1236C13.4166 18.1237 13.4161 18.124 13.0001 17.5L13.4168 18.1236L12.7921 18.5401L11.9601 17.292L12.5834 16.8764L12.5859 16.8747L12.5996 16.8654C12.6125 16.8564 12.6327 16.8424 12.6589 16.8236C12.7115 16.786 12.7874 16.7302 12.8771 16.6599C13.059 16.5172 13.2851 16.3245 13.4842 16.112C13.6922 15.8902 13.8254 15.6944 13.8799 15.5492C13.9005 15.4943 13.903 15.4643 13.9032 15.4543C13.9024 15.4531 13.9014 15.4517 13.9001 15.45C13.8723 15.413 13.7858 15.324 13.6017 15.1893C13.4272 15.0617 13.1992 14.9174 12.9248 14.7627C12.3764 14.4536 11.6795 14.1231 10.9354 13.8194C10.1922 13.516 9.41594 13.2451 8.71131 13.0513C7.99383 12.8539 7.39889 12.75 7.00011 12.75C6.17953 12.75 5.31066 13.0614 4.49722 13.5262Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9 4.75C8.86192 4.75 8.75 4.86192 8.75 5V9.75H7.25V5C7.25 4.03351 8.03348 3.25 9 3.25H21C21.9665 3.25 22.75 4.03351 22.75 5V13.75H21.25V5C21.25 4.86192 21.1381 4.75 21 4.75H9Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.75 4C11.75 3.58579 12.0858 3.25 12.5 3.25H17.5C17.9142 3.25 18.25 3.58579 18.25 4V8.5C18.25 8.91421 17.9142 9.25 17.5 9.25H12.5C12.0858 9.25 11.75 8.91421 11.75 8.5V4ZM13.25 4.75V7.75H16.75V4.75H13.25Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }