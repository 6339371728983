var render = function render(){
  var _vm$product, _vm$product$flags;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    }
  }, 'v-img', {
    src: _vm.src
  }, false), [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.src,
      expression: "!src"
    }],
    attrs: {
      "absolute": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-image-broken")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [(_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$flags = _vm$product.flags) !== null && _vm$product$flags !== void 0 && _vm$product$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-12px"
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s(_vm.purchase._option ? "옵션" : "") + " " + _vm._s(_vm.purchase._supply ? "추가" : "") + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v(_vm._s(_vm.$decode__productOptionName(_vm.option)))])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }