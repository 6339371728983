<template>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-exchange">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.213 1.30711C14.4932 1.19103 14.8158 1.25519 15.0303 1.46969L22.5303 8.96969C22.8232 9.26258 22.8232 9.73746 22.5303 10.0304L15.0303 17.5304C14.8158 17.7449 14.4932 17.809 14.213 17.6929C13.9327 17.5768 13.75 17.3034 13.75 17V12.75H9C8.58579 12.75 8.25 12.4142 8.25 12V8.40279L3.01077 14.0162L8.25 20.0039V16C8.25 15.5858 8.58579 15.25 9 15.25H12.25V16.75H9.75V22C9.75 22.3125 9.55629 22.5922 9.26379 22.7021C8.9713 22.812 8.64132 22.729 8.43557 22.4939L1.43557 14.4939C1.1821 14.2042 1.18907 13.7697 1.45171 13.4883L8.45171 5.98828C8.6619 5.76308 8.98843 5.6893 9.27503 5.80227C9.56163 5.91524 9.75 6.19196 9.75 6.50002V11.25H14.5C14.9142 11.25 15.25 11.5858 15.25 12V15.1894L20.9393 9.50002L15.25 3.81068V8.00002C15.25 8.41424 14.9142 8.75002 14.5 8.75002H11.25V7.25002H13.75V2.00002C13.75 1.69668 13.9327 1.4232 14.213 1.30711Z" />
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-exchange {
    width: 24px;
    color: var(--v-primary-base);
}
</style>
