var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-gift",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.75 10C2.75 9.58579 3.08579 9.25 3.5 9.25H20.5C20.9142 9.25 21.25 9.58579 21.25 10V22C21.25 22.4142 20.9142 22.75 20.5 22.75H3.5C3.08579 22.75 2.75 22.4142 2.75 22V10ZM4.25 10.75V21.25H19.75V10.75H4.25Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.75 9.25V22.75H11.25V9.25H12.75Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.75 21.25H21.25V22.75H2.75V21.25Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.25 6C1.25 5.58579 1.58579 5.25 2 5.25H22C22.4142 5.25 22.75 5.58579 22.75 6V10C22.75 10.4142 22.4142 10.75 22 10.75H2C1.58579 10.75 1.25 10.4142 1.25 10V6ZM2.75 6.75V9.25H21.25V6.75H2.75Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.00011 0.939331L12.0001 4.93933L16.0001 0.939331L17.0608 1.99999L12.5304 6.53032C12.2375 6.82321 11.7627 6.82321 11.4698 6.53032L6.93945 1.99999L8.00011 0.939331Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }