<template>
    <v-data-table v-bind="{ headers, items, itemClass }" dense hide-default-footer disable-filtering disable-pagination disable-sort :items-per-page="-1" fixed-header mobile-breakpoint="768" class="v-data-table--default v-data-table--tit v-data-table--no-hover">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
        <template #[`item.purchase`]="{ item }">
            <purchase-item :value="item" mode="view" class="text-left" />
        </template>
        <template #[`item.defaultPrice`]="{ item }">
            {{ (item?.salePrice + item?.discountPrice)?.format?.() }}
        </template>
        <template #[`item.statusText`]="{ item, value }">
            <v-layout justify-center align-center>
                <span>{{ value }}</span>
                <order-view-delivery :purchase="item">
                    <template #activator="{ attrs, on, shows }">
                        <v-btn v-if="shows" icon text small v-bind="attrs" v-on="on"> <v-icon small>mdi-truck-outline</v-icon> </v-btn>
                    </template>
                </order-view-delivery>
                <order-view-claim :purchase="item">
                    <template #activator="{ attrs, on, shows }">
                        <v-btn v-if="shows" icon text small v-bind="attrs" v-on="on"> <v-icon small>mdi-clipboard-alert-outline</v-icon> </v-btn>
                    </template>
                </order-view-claim>
            </v-layout>
        </template>
    </v-data-table>
</template>

<script>
import { PURCHASE_ORDER_STATUSES } from "@/assets/variables";

import PurchaseItem from "../purchase/purchase-item.vue";
import OrderViewClaim from "./order-view-claim.vue";
import OrderViewDelivery from "./order-view-delivery.vue";

const headers = [
    { width: "42%", text: "상품", value: "purchase", align: "center" },
    { width: "16%", text: "금액", value: "defaultPrice", align: "center" },
    { width: "8%", text: "수량", value: "amount", align: "center" },
    { width: "8%", text: "할인", value: "discountPrice", align: "center", formatter: (value, item) => (value + item?.levelDiscountPrice)?.format?.() || value + item?.levelDiscountPrice || "-" },
    { width: "10%", text: "상태", value: "statusText", align: "center" },
    // { width: "16%", text: "요청사항", value: "requestMessage", align: "center" },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        PurchaseItem,
        OrderViewClaim,
        OrderViewDelivery,
    },
    props: {
        purchases: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [...this.purchases].map((item) => ({ ...item, statusText: this.$getStatusText(item) }));
        },
    },
    methods: {
        itemClass(item) {
            let className = "";
            if (PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value == item?.orderStatus) {
                className += " item--canceled";
            }
            return className.trim();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .item--canceled {
        opacity: 0.5;
    }
}
</style>
