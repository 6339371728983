var render = function render(){
  var _vm$product$thumb, _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-img', {
    attrs: {
      "min-width": "100",
      "aspect-ratio": 1 / 1,
      "src": (_vm$product$thumb = _vm.product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('router-link', {
    staticClass: "d-block mb-12px",
    attrs: {
      "to": `/shop/products/${(_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._id}`
    }
  }, [_c('span', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(_vm.product.name))])]), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm.purchase._option ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("옵션")]) : _vm._e(), _vm.purchase._supply ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs d-sm-inline-block pt-sm-4px"
  }, [_vm._v(" " + _vm._s(_vm.$decode__productOptionName(_vm.purchase.name)) + " / " + _vm._s(_vm.purchase.amount.format()) + "개 "), _vm.purchase._supply ? _c('span', [_vm._v(" (+" + _vm._s(_vm.purchase.price.format()) + "원)")]) : _vm._e()])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }