var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.inquirable ? _c('inquire-form', {
    attrs: {
      "product": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.product
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }