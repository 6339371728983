var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "클레임정보",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          shows: _vm.shows
        })];
      }
    }], null, true)
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "value": _vm.purchase,
      "mode": "view"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }