<template>
    <v-sheet>
        <template v-for="(order, index) in items">
            <list-item
                v-bind="{
                    value: order,
                    points,
                }"
                v-on="{
                    init,
                }"
                @input="(o) => (orders[index] = o)"
                :key="order._id"
            />
        </template>

        <v-fade-transition>
            <div v-show="!loading && !items?.length" class="text-center pt-80px">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-view-list</v-icon>
                </div>
                <div class="tit tit--sm">주문하신 상품이 없습니다.</div>
                <div class="txt txt--sm mt-8px">원하시는 상품을 주문해주세요.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-fade-transition>

        <div v-if="hasMoreItems" v-intersect.quiet="loadInfinite" class="btn-wrap">
            <v-btn v-bind="{ ...btn_secondary, loading }" x-large block @click="loadMore"> 더 보기 </v-btn>
        </div>
    </v-sheet>
</template>

<script>
import api from "@/api";

import { PURCHASE_QUERY_CODES, ISSUE_EVENT_TYPES, ISSUE_TYPES } from "@/assets/variables/constants";
import { btn_primary, btn_secondary } from "@/assets/variables/attrs";

import ListItem from "./list/list-item.vue";

export default {
    components: {
        ListItem,
    },
    props: {
        code: { type: [String, Array], default: null },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary,
        btn_secondary,

        orders: [],
        purchases: [],
        points: {
            imagePoint: null,
            reviewPoint: null,
        },

        limit: 10,
        summary: { totalCount: 0 },
    }),
    computed: {
        items() {
            return this.orders.map((order) => ({ ...order, purchases: [...(this.purchases || [])].filter((purchase) => order?._id == purchase?._order) }));
        },

        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit || 0;
        },

        headers() {
            let { page, skip, limit } = this;
            if (this.purchases.length < skip) {
                skip = this.purchases.length;
                limit = limit * page - this.purchases.length;
            }
            return { skip, limit };
        },
        params() {
            let { ...query } = this.$route.query;
            const { code } = this;
            return { ...query, ...(PURCHASE_QUERY_CODES[code]?.params || {}) };
        },

        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        hasMoreItems() {
            return this.purchases.length < this.summary.totalCount;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            if (this.page == 1) this.init();
            else this.search();
        },
    },
    methods: {
        async init() {
            this.$emit("update:loading", false);

            this.orders = [];
            this.purchases = [];
            this.summary = { totalCount: 0 };

            this.search();
        },

        async search() {
            if (this.loading) return;
            this.$emit("update:loading", true);

            try {
                let { headers, params } = this;
                let { summary, purchases } = await api.v1.me.purchases.gets({
                    headers,
                    params,
                });

                let { points: reviewPoint } = await api.v1.shop.points.getQualifiedPoints({ params: { issueType: ISSUE_TYPES.INSTANT.value, issueEventType: ISSUE_EVENT_TYPES.REVIEW_TEXT_WRITE.value } });
                let { points: imagePoint } = await api.v1.shop.points.getQualifiedPoints({ params: { issueType: ISSUE_TYPES.INSTANT.value, issueEventType: ISSUE_EVENT_TYPES.REVIEW_IMG_UPLOAD.value } });

                this.points.reviewPoint = reviewPoint?.[0];
                this.points.imagePoint = imagePoint?.[0];

                this.summary = summary;
                let orders = purchases.reduce((orders, { order }) => (orders.some((item) => item._id == order._id) ? orders : [...orders, order]), []);
                for (const order of orders) {
                    this.updateOrder(order);
                }
                this.purchases = this.purchases.concat(purchases);
            } finally {
                this.$nextTick(() => this.$emit("update:loading", false));
            }
        },

        async updateOrder(order) {
            let index = this.orders.findIndex(({ _id }) => _id == order._id);
            if (0 <= index) this.orders.splice(index, 1, order);
            else this.orders.push(order);
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },
        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },
    },
};
</script>
