<template>
    <v-btn v-show="shows" v-bind="{ ...$attrs, disabled, loading }" v-on="{ click }">구매확정</v-btn>
</template>

<script>
import api from "@/api";

export default {
    props: {
        order: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        shows() {
            const { shipped, completed } = this.order;
            return shipped && !completed;
        },
        disabled() {
            return !this.order?.confirmable;
        },
    },
    methods: {
        async click() {
            if (!confirm("해당 주문을 구매확정 하시겠습니까?")) return;

            if (this.loading) return;
            this.loading = true;

            try {
                let { _id } = this.order;
                await api.v1.me.orders.postConfirm({ _id });
                alert("해당 주문에 대한 구매확정이 처리되었습니다.");
                this.$emit("init");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
