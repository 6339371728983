<template>
    <purchase-dialog-claim-withdraw v-if="withdrawable" v-bind="{ value, type }" @input="$emit('init')">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
    </purchase-dialog-claim-withdraw>
</template>

<script>
import { PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import PurchaseDialogClaimWithdraw from "./purchase-dialog-claim-withdraw.vue";

export default {
    components: {
        PurchaseDialogClaimWithdraw,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value },
    },
    computed: {
        withdrawable() {
            return this.value?.claimStatus == this.type;
        },
    },
};
</script>

<style></style>
