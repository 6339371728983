<template>
    <span> <slot name="activator" v-bind="{ active, toggle }" /> </span>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // selected
        items: { type: Array, default: () => [] },
    },
    computed: {
        active() {
            if (!this.items?.length) return false;
            return !this.items.some((item) => !this.value.some(({ _id }) => _id == item?._id));
        },
    },
    methods: {
        toggle() {
            let newValue = this.active ? [] : [...this.items];
            console.log({ newValue });
            this.$emit("input", newValue);
        },
    },
};
</script>

<style></style>
