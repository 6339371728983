var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-truck",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.25 17.5C3.25 15.9812 4.48121 14.75 6 14.75C7.51876 14.75 8.75 15.9812 8.75 17.5C8.75 19.0188 7.51876 20.25 6 20.25C4.48121 20.25 3.25 19.0188 3.25 17.5ZM6 16.25C5.30965 16.25 4.75 16.8097 4.75 17.5C4.75 18.1903 5.30965 18.75 6 18.75C6.69034 18.75 7.25 18.1903 7.25 17.5C7.25 16.8097 6.69034 16.25 6 16.25Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M14.75 17.5C14.75 15.9812 15.9812 14.75 17.5 14.75C19.0188 14.75 20.25 15.9812 20.25 17.5C20.25 19.0188 19.0188 20.25 17.5 20.25C15.9812 20.25 14.75 19.0188 14.75 17.5ZM17.5 16.25C16.8097 16.25 16.25 16.8097 16.25 17.5C16.25 18.1903 16.8097 18.75 17.5 18.75C18.1903 18.75 18.75 18.1903 18.75 17.5C18.75 16.8097 18.1903 16.25 17.5 16.25Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.25 5.5C0.25 5.08579 0.585786 4.75 1 4.75H15.5C15.9142 4.75 16.25 5.08579 16.25 5.5V17.5C16.25 17.9142 15.9142 18.25 15.5 18.25H7.25V16.75H14.75V6.25H1.75V16.75H4.75V18.25H1C0.585786 18.25 0.25 17.9142 0.25 17.5V5.5Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M14.75 9C14.75 8.58579 15.0858 8.25 15.5 8.25H19.7857C20.0207 8.25 20.2421 8.36015 20.3839 8.54759L23.5982 12.7976C23.6967 12.9278 23.75 13.0867 23.75 13.25V17.5C23.75 17.9142 23.4142 18.25 23 18.25H19.1556V16.75H22.25V13.5017L19.4126 9.75H16.25V18.25H14.75V9Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }